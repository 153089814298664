import {useState, useEffect} from "react";
import LinkScroll from "@/components/LinkScroll";
import styles from "@/components/Header.module.css";
import Link from "next/link";
import Cookies from "js-cookie";
import {trackEvent} from "@/utils/Analytics";
import NavHub from "@/components/navhub/v2/NavHub";
import NavCat from '@/components/navhub/NavCat';
import Image from 'next/image';


const Header = ({theme = "light"}) => {
  const [clientIP, setClientIP] = useState(null);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setClientIP(data.ip))
      .catch((error) => console.error("Error fetching client IP:", error));
  }, []);

  const trackLinkClick = () => {
    const user_id = Cookies.get("user_id");
    const user_agent = navigator.userAgent;
    if (user_id && clientIP) {
      trackEvent(
        "Clicked Link",
        "Navigation",
        "Become a BETA tester",
        user_id,
        user_agent,
        clientIP
      );
    }
  };

  return (
    <div className={`${styles.navbar} ${theme === 'dark' ? styles.fixedNav : ''}`}>
      <div className={styles.ribbon}>
        <div className={styles.ribbonText}>
          <Link className={styles.ribbonLink} href="/ev-health-checker">
            {`🎉 BETA testing now live!`}
          </Link>
        </div>
      </div>
      <div className={`${styles.navbar1} ${theme === 'dark' ? styles.darkNav : ''}`}>
        <div className={styles.navbarContainer}>
          <div className={styles.navbarButtons}>
            <Link className={styles.cwLogo} href="/">
              <Image className={styles.vectorIcon} alt="" width={150} height={26} src={theme === 'dark' ? '/logo_white.svg' : '/vector.svg'}/>

              <span className={styles.cwBetaBadge}>BETA</span>
            </Link>
            <div className={styles.navbarButtonsCenter}>
              <b className={styles.button1}>Button1</b>
              <b className={styles.button1}>Button2</b>
              <b className={styles.button1}>Button3</b>
            </div>
            {/*
              <div className={styles.navbarButtonsRight}>
                <LinkScroll
                  className={styles.text}
                  href="#beta-program"
                  onClick={(e) => trackLinkClick()}>
                  Become a BETA tester
                </LinkScroll>
              </div>*/}
            <NavHub theme={theme}/>
            {/* <NavCat label='Menu 2' /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
